import {
  SET_MG_GAME_NAMES,
  SET_MG_GAME_IMAGES,
  SET_MG_GAME_POLA,
  SET_GAME_ISLOADING,
  SET_GAME_ISERROR
} from '../actionTypes';
import { mainAxios, customAxios } from '../../apis/axios';

const cheerio = require("cheerio");
const now = new Date();
const betForPola = ["10", "20", "30", "50", "70", "100", "500", "1000"];

export const setMGGameNames = (payload) => {
  return {
    type: SET_MG_GAME_NAMES,
    payload: payload
  };
};

export const setMGGameImages = (payload) => {
  return {
    type: SET_MG_GAME_IMAGES,
    payload: payload
  };
};

export const setMGPolas = (payload) => {
  return {
    type: SET_MG_GAME_POLA,
    payload: payload
  };
};

export const setGameIsLoading = (payload) => {
  return {
    type: SET_GAME_ISLOADING,
    payload: payload
  };
};

export const setGameIsError = (payload) => {
  return {
    type: SET_GAME_ISERROR,
    payload: payload
  };
};

export const fetchMGGamesData = () => {
  return async (dispatch) => {
    dispatch(setGameIsLoading(true));
    await customAxios(
      {
        method: 'GET',
        url: '/?content=slot&provider=mg'
      }
    )
      .then(({ data }) => {
        const $ = cheerio.load(data);
        const check = $('.gameitem');
        let tempMGName = [];
        let tempMGImg = [];
        for (let i = 0; i < check.length; i++) {
          let nameMGGame = $(check[i]).find('figcaption').text();
          let imageMGGame = $(check[i]).find('img').attr('src');
          tempMGName.push(nameMGGame);
          tempMGImg.push(imageMGGame);
        }
        dispatch(setMGGameNames(tempMGName));
        dispatch(setMGGameImages(tempMGImg));
      })
      .catch(err => {
        dispatch(setGameIsError(err));
      })
      .finally(() => {
        dispatch(setGameIsLoading(false));
      });
  };
};

export const fetchMGPolasData = () => {
  return async (dispatch) => {
    dispatch(setGameIsLoading(true));
    await mainAxios(
      {
        method: 'GET',
        url: '/getMgPolas'
      }
    )
      .then(({ data }) => {
        for (let i = 0; i < 1000; i++) {
          let value1 = `${betForPola[Math.floor(Math.random() * 8)]}`
          let value2 = `${betForPola[Math.floor(Math.random() * 8)]}`
          let value3 = `${betForPola[Math.floor(Math.random() * 8)]}`
          if (!data[i]) {
            mainAxios(
              {
                method: 'POST',
                url: '/postMgPola',
                data: {
                  value1: value1,
                  value2: value2,
                  value3: value3
                }
              }
            )
              .then(() => {
                console.log('New Pola added!');
              })
              .catch(err => {
                dispatch(setGameIsError(err));
              })
          } else if (data[i].value1 === null || data[i].value2 === null || data[i].value3 === null) {
            mainAxios(
              {
                method: 'PUT',
                url: '/updateMgPola/' + data[i].id,
                data: {
                  value1: value1,
                  value2: value2,
                  value3: value3,
                  updatedAt: now.toISOString()
                }
              }
            )
              .then(() => {
                console.log('Pole baru saja diupdate');
              })
              .catch(err => {
                dispatch(setGameIsError(err));
              })
          } else if (now.getDate() + now.getHours() !== (new Date(data[i].updatedAt).getDate()) + (new Date(data[i].updatedAt).getHours())) {
            mainAxios(
              {
                method: 'PUT',
                url: '/updateMgPola/' + data[i].id,
                data: {
                  value1: value1,
                  value2: value2,
                  value3: value3,
                  updatedAt: now.toISOString()
                }
              }
            )
              .then(() => {
                console.log('Pole baru saja diupdate');
              })
              .catch(err => {
                dispatch(setGameIsError(err));
              })
          } else {
            console.log('Pola is up to date');
          }
        }
        mainAxios(
          {
            method: 'GET',
            url: '/getMgPolas'
          }
        )
          .then(({ data }) => {
            dispatch(setMGPolas(data));
          })
      })
      .catch(err => {
        dispatch(setGameIsError(err));
      })
      .finally(() => {
        dispatch(setGameIsLoading(false));
      });
  };
};