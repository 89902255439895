import axios from 'axios';

axios.defaults.headers.post["Content-Type"] = "application/json";

const mainAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

const customAxios = axios.create({
  baseURL: 'https://untidy-robin-production.up.railway.app/api'
});

export {
  mainAxios,
  customAxios
};