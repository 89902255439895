import {
  SET_PGS_GAME_NAMES,
  SET_PGS_GAME_IMAGES,
  SET_PGS_GAME_POLA,
  SET_GAME_ISLOADING,
  SET_GAME_ISERROR
} from '../actionTypes';
import { mainAxios, customAxios } from '../../apis/axios';

const cheerio = require("cheerio");
const now = new Date();
const betForPola = ["10", "20", "30", "50", "70", "100", "500", "1000"];

export const setPGSGameNames = (payload) => {
  return {
    type: SET_PGS_GAME_NAMES,
    payload: payload
  };
};

export const setPGSGameImages = (payload) => {
  return {
    type: SET_PGS_GAME_IMAGES,
    payload: payload
  };
};

export const setPgPolas = (payload) => {
  return {
    type: SET_PGS_GAME_POLA,
    payload: payload
  };
};

export const setGameIsLoading = (payload) => {
  return {
    type: SET_GAME_ISLOADING,
    payload: payload
  };
};

export const setGameIsError = (payload) => {
  return {
    type: SET_GAME_ISERROR,
    payload: payload
  };
};

export const fetchPGSGamesData = () => {
  return async (dispatch) => {
    dispatch(setGameIsLoading(true));
    await customAxios(
      {
        method: 'GET',
        url: '/?content=slot&provider=pg'
      }
    )
      .then(({ data }) => {
        const $ = cheerio.load(data);
        const check = $('.gameitem');
        let tempPgName = [];
        let tempPgImg = [];
        for (let i = 0; i < check.length; i++) {
          let namePgGame = $(check[i]).find('figcaption').text();
          let imagePgGame = $(check[i]).find('img').attr('src');
          tempPgName.push(namePgGame);
          tempPgImg.push(imagePgGame);
        }
        dispatch(setPGSGameNames(tempPgName));
        dispatch(setPGSGameImages(tempPgImg));
      })
      // .then(({ data }) => {
      //   const $ = cheerio.load(data);
      //   const check = $('#game_list');
      //   console.log(check.length);
      // })
      .catch(err => {
        dispatch(setGameIsError(err));
      })
      .finally(() => {
        dispatch(setGameIsLoading(false));
      });
  };
};

export const fetchPgPolasData = () => {
  return async (dispatch) => {
    dispatch(setGameIsLoading(true));
    await mainAxios(
      {
        method: 'GET',
        url: '/getPgPolas'
      }
    )
      .then(({ data }) => {
        for (let i = 0; i < 1000; i++) {
          let value1 = `${betForPola[Math.floor(Math.random() * 8)]}`
          let value2 = `${betForPola[Math.floor(Math.random() * 8)]}`
          let value3 = `${betForPola[Math.floor(Math.random() * 8)]}`
          if (!data[i]) {
            mainAxios(
              {
                method: 'POST',
                url: '/postPgPola',
                data: {
                  value1: value1,
                  value2: value2,
                  value3: value3
                }
              }
            )
              .then(() => {
                console.log('New Pola added!');
              })
              .catch(err => {
                dispatch(setGameIsError(err));
              })
          } else if (data[i].value1 === null || data[i].value2 === null || data[i].value3 === null) {
            mainAxios(
              {
                method: 'PUT',
                url: '/updatePgPola/' + data[i].id,
                data: {
                  value1: value1,
                  value2: value2,
                  value3: value3,
                  updatedAt: now.toISOString()
                }
              }
            )
              .then(() => {
                console.log('Pole baru saja diupdate');
              })
              .catch(err => {
                dispatch(setGameIsError(err));
              })
          } else if (now.getDate() + now.getHours() !== (new Date(data[i].updatedAt).getDate()) + (new Date(data[i].updatedAt).getHours())) {
            mainAxios(
              {
                method: 'PUT',
                url: '/updatePgPola/' + data[i].id,
                data: {
                  value1: value1,
                  value2: value2,
                  value3: value3,
                  updatedAt: now.toISOString()
                }
              }
            )
              .then(() => {
                console.log('Pole baru saja diupdate');
              })
              .catch(err => {
                dispatch(setGameIsError(err));
              })
          } else {
            console.log('Pola is up to date');
          }
        }
        mainAxios(
          {
            method: 'GET',
            url: '/getPgPolas'
          }
        )
          .then(({ data }) => {
            dispatch(setPgPolas(data));
          })
      })
      .catch(err => {
        dispatch(setGameIsError(err));
      })
      .finally(() => {
        dispatch(setGameIsLoading(false));
      });
  };
};